<template>
    <div class="vertical">
        <div class="content">
            <div style="background-color: #fff;">
                <div class="content-image" :style="{backgroundImage: `url(${article_info.img})`}" v-if="article_info.img"></div>
                <div class="content-image-nodata" :style="{ 'background': 'url('+require('@/assets/no_img.png')+')'}" v-else></div>
            </div>
            <div class="right">
                <div class="texteare">
                    <div class="title line-one">{{article_info.title}}</div>
                    <div class="second-title line-one">{{lang.project_layout_introduce}}</div>
                    <div class="weight-line"></div>
                    <div class="text line-six">{{ article_info.info.replace(/<[^>]*>/g, '') }}</div>
                </div>
                <div class="footer">
                    <div class="text-item column-center">
                        <img class="image-item" src="@/assets/projectLayout/item-top.png"/>
                        <div class="line-five">{{ article_info.description2 }}</div>
                    </div>
                    <div class="text-item column-center">
                        <div class="line-five">{{ article_info.description3 }}</div>
                        <img class="image-item" src="@/assets/projectLayout/item-bottom.png"/>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</template>

<script>

import { getArticleInfo } from "@/api/store";
import {
			mapState
	} from 'vuex'
	export default {
		name: "vertical",
		computed: {
			...mapState({
			  lang: state => state.user.lang,
			})
		},
		data() {
			return {
                id:this.$props.id,
                article_info:{}
			}
		},
		props: {
			id: {
				type: String,
				default: ''
			},
		},
        created(){
            this.getArticleInfoFun()
        },
		methods:{
            getArticleInfoFun(){
                let data = {
                    id:this.id
                }
                getArticleInfo(data).then(res=>{
                    this.article_info = res.data.article_info
                })
            },
			
		}

	}
</script>

<style lang="less" scoped>
/* 响应式布局 */
@media (max-width: 768px) {
  .vertical{
    width:100% !important;
    .content{
        width: 100%;
        margin: 0 auto;
        height: auto !important;
        flex-direction: column;
        .content-image{
            width: 100% !important;
            height: 15.13rem !important;
        }
        .content-image-nodata{
            width: 100% !important;
            height: 15.13rem !important;
        }
        .right{
            width: 100% !important;
            .texteare{
                width: 100% !important;
            }
            .footer{
                width: 100% !important;
                .text-item{
                    width: 48% !important;
                }
            }
        }
    }
  }
}
    .vertical{
        width: 75rem;
        .content{
            width: 100%;
            height: 35.75rem;
            display: flex;
            justify-content: space-between;
            .content-image{
                width: 36.75rem;
                height: 35.75rem;
                background-size: auto 100%;
                background-position: center; /* 可选，确保图片居中 */
                background-repeat: no-repeat; /* 可选，确保不重复 */
            }
            .content-image-nodata{
                width: 36.75rem;
                height: 35.75rem;
                background-size: auto 50% !important;
                background-position: center !important; /* 可选，确保图片居中 */
                background-repeat: no-repeat !important; 
            }
            .right{
                width: 36.75rem;
                height: 100%;
                .texteare{
                    width: 36.75rem;
                    height: 17.13rem;
                    padding: 1rem 1.5rem;
                    background-color: #fff;
                    // padding: 3rem 2.5rem;
                    .title{
                        font-weight: bold;
                        font-size: 1.5rem;
                    }
                    .second-title{
                        font-weight: bold;
                        font-size: 1.25rem;
                    }
                    .weight-line{
                        width: 6rem;
                        height: 1rem;
                        margin: 0.2rem 0;
                        background: #B60914;
                    }
                    .text{
                        width: 100%;
                        margin-top: 0.5rem;
                        font-size: 1rem;
                        color: #666666;
                        line-height: 1.5rem;
                    }
                }
                .footer{
                    width: 100%;
                    height: 17.13rem;
                    margin-top: 1.5rem;
                    display: flex;
                    justify-content: space-between;
                    .text-item{
                        height: 100%;
                        width: 17.63rem;
                        padding: 0 1.5rem;
                        color: #666666;
                        line-height: 1.5rem;
                        background-color: #fff;
                        .image-item{
                            width: 11.44rem;
                            height: 6.69rem;
                        }
                    }
                    .text-item :first-child{margin-bottom:1rem}
                }

            }
        }
    }
</style>