<template>
	<div class="projectLayoutsee">
        <div class="content">
            <level v-if="type == 'level'" :id="id"></level>
            <vertical v-if="type == 'vertical'" :id="id"></vertical>
        </div>
	</div>
</template>

<script>
import vertical from "@/components/projectLayout/vertical.vue"
import level from "@/components/projectLayout/level.vue"
	export default {
		name: "projectLayoutsee",
		data() {
			return {
				id: this.$route.params.id,
                currentPage3:1,
                type: '',
                typesetting: [{
                    name: '横版',
                    type: 'level',
                    isShow: true
                }, {
                    name: '竖版',
                    type: 'vertical',
                    isShow: false
                },]
			}
		},
		components:{
			level,
			vertical,
		},
		created() {
            if(this.$route.query.type == 1){
                 this.type = 'level'
            }else if(this.$route.query.type == 2){
                 this.type = 'vertical'
            }
		},
        beforeRouteUpdate(to, from, next) {
            console.log(to,'to')
            this.id = to.params.id
            if(to.query.type == 1){
                 this.type = 'level'
            }else if(to.query.type == 2){
                 this.type = 'vertical'
            }
            next();
        },
		mounted() {
		},
		updated() {
		},
		methods:{
			path(routePath){
				 this.$router.push(routePath); 
			}
		}




	}
</script>

<style lang="less" scoped>

@media (max-width: 768px) {
  .projectLayoutsee{
    width: 100% !important;
    .content{
        width: 100% !important;
        padding: 0 1rem;
    }
  }
}
.projectLayoutsee{
    background: #f2f2f2 url('../../assets/news_detail_bj.png') no-repeat;
    background-size: 100% auto;
    width: 100%;
	padding: 8rem 0;
    .content{
        margin: 0 auto;
        width: 75rem;
        .header{
            padding: 1.3rem 0;
            display: flex;
            align-items: center;
            .header-image{
                margin-right: 0.5rem;
                width: 1.31rem;
                height: 1.31rem;
            }
        }
    }
}
</style>