<template>
    <div class="level">
        <div class="content">
            <div class="content-image" :style="{backgroundImage: `url(${article_info.img})`}" v-if="article_info.img"></div>
            <div class="content-image-nodata" :style="{ 'background': 'url('+require('@/assets/no_img.png')+')'}" v-else></div>
            <div class="texteare">
                <div class="title line-one">{{article_info.title}}</div>
                <div class="second-title line-one">{{lang.project_layout_background}}</div>
                <div class="weight-line"></div>
                <div class="text line-ten">{{ article_info.info.replace(/<[^>]*>/g, '') }}</div>
            </div>
        </div>
        <div class="footer">
            <div class="text-left column-center">
                <div class="title">{{lang.project_layout_content}}</div>
                <img class="image-item" src="@/assets/projectLayout/item-left.png"/>
                <div class="line-four">{{ article_info.description2 }}</div>
            </div>
            <div class="text-left column-center">
                <div class="title">{{lang.project_layout_vision}}</div>
                <img class="image-item" src="@/assets/projectLayout/item-right.png"/>
                <div class="line-four">{{ article_info.description3 }}</div>
            </div>
        </div>
    </div>
</template>

<script>

import { getArticleInfo } from "@/api/store";
import {
			mapState
	} from 'vuex'
	export default {
		name: "level",
		computed: {
			...mapState({
			  lang: state => state.user.lang,
			})
		},
		data() {
			return {
                id:this.$props.id,
                article_info:{}
			}
		},
		props: {
			id: {
				type: String,
				default: ''
			},
		},
        created(){
            this.getArticleInfoFun()
        },
		methods:{
            getArticleInfoFun(){
                let data = {
                    id:this.id
                }
                getArticleInfo(data).then(res=>{
                    this.article_info = res.data.article_info
                })
            },
			
		}


	}
</script>

<style lang="less" scoped>
/* 响应式布局 */
@media (max-width: 768px) {
  .level{
    width:100% !important;
    .content{
        width: 100%;
        margin: 0 auto;
        flex-direction: column;
        .content-image{
            width: 100% !important;
            background-position: center;
            height: 15.13rem !important;
        }
        .content-image-nodata{
            width: 100% !important;
            background-position: center;
            height: 15.13rem !important;
        }
        .texteare{
            width: 100% !important;
            .text{
                width: 100% !important;
            }
        }
    }
    
    .footer{
        .text-left{
            width: 49% !important;
            .image-item{
                width: 100% !important;
                height: auto !important;
            }
        }
    }
  }
}
    .level{
        width: 75rem;
        .content{
            background-color: #fff;
            width: 100%;
            min-height: 27.88rem;
            display: flex;
            .content-image{
                width: 51rem;
                height: auto;
                background-size: auto 100%;
                background-position: left;
                background-repeat: no-repeat; /* 可选，确保不重复 */
            }
            .content-image-nodata{
                width: 51rem;
                height: auto;
                background-size: auto 50% !important;
                background-position: center !important; ; /* 可选，确保图片居中 */
                background-repeat: no-repeat !important; 
            }
            .texteare{
                width: 24rem;
                height: 100%;
                padding: 3rem 2.5rem;
                .title{
                    font-weight: bold;
                    font-size: 1.5rem;
                }
                .second-title{
                    font-weight: bold;
                    font-size: 1rem;
                }
                .weight-line{
                    width: 6rem;
                    height: 1rem;
                    margin: 1.3rem 0;
                    background: #B60914;
                }
                .text{
                    width: 18.44rem;
                    font-size: 1rem;
                    color: #666666;
                    line-height: 1.5rem;
                }
            }
        }
        .footer{
            width: 100%;
            height: 22.75rem;
            margin-top: 1.5rem;
            display: flex;
            justify-content: space-between;
            .text-left{
                width: 36.75rem;
                height: 100%;
                padding: 2rem;
                color: #333333;
                line-height: 1.5rem;
                background-color: #fff;
                justify-content: space-between;
                .image-item{
                    width: 32.5rem;
                    height: 9.5rem;
                }
                .title{
                    width: 100%;
                    border-left: 0.33rem #B60914 solid;
                    padding-left: 1rem;
                    font-size: 1rem;
                    font-weight: bold;
                    color: #333333;
                }
            }
        }
    }
</style>